import { Box, Button, Stack, Typography } from "@mui/material";
import { useClient } from "../../hooks/Client";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  padding: {
    padding: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  loginContainer: {
    textAlign: "center",
  },
  buttons: {
    padding: "5px",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    backgroundImage: (props: any) => `url('${props.logo}')`,
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    display: "block",
    width: "480px",
    height: "220px",
  },
}));
const ErrorPage = () => {
  const history = useHistory();
  const { client } = useClient();
  const classes = useStyles({ logo: client.logo });
  return (
    <Stack
      direction={"row"}
      sx={{ height: "100vh" }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Stack
        direction="column"
        justifyContent={"stretch"}
        alignItems={"center"}
        spacing={2}
      >
        <Box component="div" className={classes.logoContainer}>
          <Box component="div" className={classes.logo}></Box>
        </Box>
        <Typography fontSize={21} fontWeight={700}>
          We&apos;re sorry, but something went wrong
        </Typography>
        <Typography fontSize={21} fontWeight={700}>
          Please try again
        </Typography>
        <Button
          type="button"
          variant="contained"
          size="large"
          sx={{ input: { cursor: "pointer" } }}
          color="primary"
          onClick={() => history.push("/")}
        >
          <Typography>Go to Home</Typography>
        </Button>
      </Stack>
    </Stack>
  );
};

export default ErrorPage;
