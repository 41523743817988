import { createTheme } from '@mui/material/styles';

const GirasolTheme = createTheme({
  palette: {
    common: {
        black: "#272727"
    },
    primary: {
        main: "#0074ad",
        light: "#54a2df",
        dark: "#00497d"
    },
    secondary: {
      main: "#23a455",
      light: "#60d683",
      dark: "#00742a"
    }
  }
});
export default GirasolTheme;