import axios, { AxiosInstance } from "axios";
import { RoleType } from "../models/IUser";
import { IUserAuthenticated } from "../models/IUserAuthenticated";
import { appsettings } from "../app.config";
import jwt_decode from "jwt-decode";
import { ICuberJwtPayload } from "../models/ICuberJwtPayLoad";
import { IClient } from "../hooks/Client/ClientContext";

export interface IAuthenticationService {
  getAuthenticatedApiInstance(): AxiosInstance;
  getAnonymousApiInstance(): AxiosInstance;
  refreshAccessToken(): Promise<string>;
  logout(): Promise<void>;
  login(email: string, password: string): Promise<boolean>;
  getUser(): IUserAuthenticated | undefined;
  isAuthenticated(): boolean;
  getAccessToken(): string;
}

export default class AuthenticationService implements IAuthenticationService {
  private sessionStorageKey: string = "tpk";

  getAccessToken(): string {
    if (!this.isAuthenticated()) {
      return null;
    }

    const value: string | null = window.sessionStorage.getItem(
      this.sessionStorageKey
    );
    if (value === null) {
      return null;
    }
    const keys: {
      accessToken: string;
      accessTokenExpirationDate: number;
      refreshToken: string;
    } = JSON.parse(value);
    if (keys.accessToken === null) {
      return null;
    }
    return keys.accessToken;
  }

  isAuthenticated(): boolean {
    const value: string | null = window.sessionStorage.getItem(
      this.sessionStorageKey
    );
    if (value === null) {
      return false;
    }
    const keys: {
      accessToken: string;
      accessTokenExpirationDate: number;
      refreshToken: string;
    } = JSON.parse(value);
    if (keys.accessToken === null) {
      return false;
    }
    if (keys.accessTokenExpirationDate < new Date().getTime() / 1000) {
      window.sessionStorage.removeItem(this.sessionStorageKey);
      return false;
    }
    return true;
  }

  getUser(): IUserAuthenticated | undefined {
    const value: string | null = window.sessionStorage.getItem(
      this.sessionStorageKey
    );
    if (value === null) {
      return null;
    }
    const keys: {
      accessToken: string;
      accessTokenExpirationDate: number;
      refreshToken: string;
    } = JSON.parse(value);
    const jwtDecode: ICuberJwtPayload = jwt_decode<ICuberJwtPayload>(
      keys.accessToken
    );
    return {
      email: jwtDecode.email,
      userId: jwtDecode.sid,
      givenName: jwtDecode.given_name,
      surName: jwtDecode.family_name,
      initials: this.getInitials(jwtDecode.given_name, jwtDecode.family_name),
      role: this.getRole(jwtDecode.role),
    };
  }

  async login(email: string, password: string): Promise<boolean> {
    const loginRequestInfo: { method: string; endpoint: string } =
      appsettings.serviceUrls.authentication.login;

    const appClientString = window.sessionStorage.getItem("appclient");
    const appClient = appClientString
      ? (JSON.parse(appClientString) as IClient)
      : null;

    const response: Response = await fetch(loginRequestInfo.endpoint, {
      method: loginRequestInfo.method,
      headers: new Headers({
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Cross-Origin-Embedder-Policy": "require-corp",
        "Cross-Origin-Opener-Policy": "same-origin",
        TenantId: appClient ? appClient.domain : document.location.hostname,
      }),
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });
    if (!response.ok) {
      const text = await response.text();
      throw new Error(
        text.includes("TypeError") ? "Something went wrong." : text
      );
    }

    const responseJson: any = await response.json();
    window.sessionStorage.setItem(
      this.sessionStorageKey,
      JSON.stringify(responseJson)
    );
    return true;
  }

  async logout(): Promise<void> {
    const value: string | null = window.sessionStorage.getItem(
      this.sessionStorageKey
    );
    if (value === null) {
      return;
    }
    const keys: {
      accessToken: string;
      accessTokenExpirationDate: number;
      refreshToken: string;
    } = JSON.parse(value);
    const logoutRequestInfo: { method: string; endpoint: string } =
      appsettings.serviceUrls.authentication.logout;

    const appClientString = window.sessionStorage.getItem("appclient");
    const appClient = appClientString
      ? (JSON.parse(appClientString) as IClient)
      : null;

    const headers: any = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Content-Type": "application/json",
      "Cross-Origin-Embedder-Policy": "require-corp",
      "Cross-Origin-Opener-Policy": "same-origin",
      TenantId: appClient ? appClient.domain : document.location.hostname,
    };
    headers[appsettings.authHeaderName] = `Bearer ${keys.accessToken}`;
    const response: Response = await fetch(logoutRequestInfo.endpoint, {
      method: logoutRequestInfo.method,
      headers: headers,
    });
    if (response.ok) {
      window.sessionStorage.removeItem(this.sessionStorageKey);
      window.location.href = "/login";
    } else {
      window.location.href = "/login";
    }
  }

  async refreshAccessToken(): Promise<string> {
    const value: string | null = window.sessionStorage.getItem(
      this.sessionStorageKey
    );
    if (value === null) {
      throw new Error("Access Token not found.");
    }
    const keys: {
      accessToken: string;
      accessTokenExpirationDate: number;
      refreshToken: string;
    } = JSON.parse(value);
    const jwtDecode: ICuberJwtPayload = jwt_decode<ICuberJwtPayload>(
      keys.accessToken
    );
    const refreshTokenRequestInfo: { method: string; endpoint: string } =
      appsettings.serviceUrls.authentication.refreshToken;

    const appClientString = window.sessionStorage.getItem("appclient");
    const appClient = appClientString
      ? (JSON.parse(appClientString) as IClient)
      : null;

    const response: Response = await fetch(refreshTokenRequestInfo.endpoint, {
      method: refreshTokenRequestInfo.method,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Content-Type": "application/json",
        "Cross-Origin-Embedder-Policy": "require-corp",
        "Cross-Origin-Opener-Policy": "same-origin",
        TenantId: appClient ? appClient.domain : document.location.hostname,
      },
      body: JSON.stringify({
        userId: jwtDecode.sid,
        refreshtoken: keys.refreshToken,
      }),
    });
    if (!response.ok) {
      const message: string = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    const responseJson: any = await response.json();
    window.sessionStorage.setItem(
      this.sessionStorageKey,
      JSON.stringify(responseJson)
    );
    return responseJson.accessToken;
  }

  getAuthenticatedApiInstance(): AxiosInstance {
    const authenticatedApiInstance: AxiosInstance = axios.create();

    authenticatedApiInstance.interceptors.request.use(
      (config) => {
        const value: string | null = window.sessionStorage.getItem(
          this.sessionStorageKey
        );
        if (value === null) {
          throw new Error("Access Token not found.");
        }

        const appClientString = window.sessionStorage.getItem("appclient");
        const appClient = appClientString
          ? (JSON.parse(appClientString) as IClient)
          : null;

        const keys: {
          accessToken: string;
          accessTokenExpirationDate: number;
          refreshToken: string;
        } = JSON.parse(value);
        config.headers = {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Cross-Origin-Embedder-Policy": "require-corp",
          "Cross-Origin-Opener-Policy": "same-origin",
          TenantId: appClient ? appClient.domain : document.location.hostname,
        };
        config.headers[
          appsettings.authHeaderName
        ] = `Bearer ${keys.accessToken}`;
        if (config.data instanceof FormData) {
          Object.assign(config.headers, {
            "Content-Type": "multipart/form-data",
          });
        } else {
          Object.assign(config.headers, {
            "Content-Type": "application/json",
          });
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    authenticatedApiInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const originalRequest: any = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const authService: AuthenticationService =
            new AuthenticationService();
          const access_token: string = await authService.refreshAccessToken();
          axios.defaults.headers.common[appsettings.authHeaderName] =
            "Bearer " + access_token;
          return authenticatedApiInstance(originalRequest);
        }
        return Promise.reject(error);
      }
    );

    return authenticatedApiInstance;
  }

  getAnonymousApiInstance(): AxiosInstance {
    return axios.create();
  }

  private getRole(role: number | undefined): RoleType {
    if (role === undefined) {
      throw new Error("Role not found");
    }

    switch (role) {
      case 0:
        return RoleType.Administrator;
      case 1:
        return RoleType.Operator;
      case 2:
        return RoleType.Guest;
      default:
        throw new Error("Role not found");
    }
  }

  private getInitials(
    givenName: string | undefined,
    lastName: string | undefined
  ): string {
    if (givenName === undefined) {
      throw new Error("Given Name not found");
    }

    if (lastName === undefined) {
      throw new Error("Last Name not found");
    }

    return givenName[0].toUpperCase() + lastName[0].toUpperCase();
  }
}
