import { createContext } from "react";
import { IUserAuthenticated } from "../../models/IUserAuthenticated";

export interface IAuthContextProps {
  isAuthenticated: boolean;
  user: IUserAuthenticated;
  signin: (
    email: string,
    password: string,
    callback: () => void
  ) => Promise<boolean>;
  signout: (callback: () => void) => Promise<void>;
  accessToken: () => string;
}

const AuthContext: React.Context<IAuthContextProps> = createContext(null as IAuthContextProps);

export default AuthContext;