import { useState } from "react";
import { IUserAuthenticated } from "../../models/IUserAuthenticated";
import AuthenticationService, {
  IAuthenticationService,
} from "../../services/AuthenticationService";
import { IAuthContextProps } from "./AuthContext";

const useProvideAuth: () => IAuthContextProps = () => {
  const authenticationService: IAuthenticationService =
    new AuthenticationService();
  const [user, setUser] = useState(authenticationService.getUser());
  const [isAuthenticated, setIsAuthenticated] = useState(
    authenticationService.isAuthenticated()
  );

  const signin: (
    email: string,
    password: string,
    callback: () => void
  ) => Promise<boolean> = async (
    email: string,
    password: string,
    callback: () => void
  ) => {
    const result: boolean = await authenticationService.login(email, password);
    if (result) {
      const user: IUserAuthenticated = authenticationService.getUser();
      setUser(user);
      setIsAuthenticated(true);
      callback();
    }
    return result;
  };

  const signout: (callback: () => void) => Promise<void> = async (
    callback: () => void
  ) => {
    await authenticationService.logout();
    setUser(null);
    setIsAuthenticated(false);
    callback();
  };

  const accessToken: () => string = () => {
    try {
      return authenticationService.getAccessToken();
    } catch (error) {
      return "";
    }
  };

  return {
    isAuthenticated,
    user,
    signin,
    signout,
    accessToken,
  };
};

export default useProvideAuth;
