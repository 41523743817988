import { createContext } from "react";
import { Features } from "../../models/Features";

export interface IClient {
  title: string;
  name: string;
  logo: string;
  type: string;
  backgroundLoginLeft: string;
  description: string;
  domain: string;
  copyright: string;
  features: Features[];
  pagePath: string;
}

export interface IClientContextProps {
  client: IClient;
}

const ClientContext: React.Context<IClientContextProps> = createContext(
  null as IClientContextProps
);

export default ClientContext;
