import { IClientContextProps, IClient } from "./ClientContext";
import { appsettings } from "../../app.config";
import { useEffect, useState } from "react";

declare global {
  interface Window {
    appclient: any;
  }
}

const useProvideClient: () => IClientContextProps = () => {
  const getDefaultClient: () => IClient = () => {
    const defaultClient = appsettings.clients["default"];
    return appsettings.clients[defaultClient];
  };
  const [client, setClient] = useState(getDefaultClient());

  useEffect(() => {
    if (window.location.hostname === "localhost") {
      const defaultClientName = appsettings.clients["default"];
      const defaultClient = appsettings.clients[defaultClientName];
      document.title = defaultClient.title;
      window.top.document.title = defaultClient.title;
      window.sessionStorage.setItem("appclient", JSON.stringify(defaultClient));
      setClient(defaultClient);
    }

    for (const key in appsettings.clients) {
      const item: IClient = appsettings.clients[key] as IClient;
      if (key === window.location.hostname) {
        document.title = item.title;
        window.top.document.title = item.title;
        window.sessionStorage.setItem("appclient", JSON.stringify(item));
        setClient(item);
        break;
      }
    }
  }, [window.location]);

  return { client };
};

export default useProvideClient;
