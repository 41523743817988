import { createTheme }  from "@mui/material/styles";

const GeniusTheme = createTheme({
  palette: {
    common: {
        black: "#272727"
    },
    primary: {
        main: "#0075AD",
        light: "#3390BD",
        dark: "#005179"
    },
    secondary: {
      main: "#A8D2F2",
      light: "#B9DBF4",
      dark: "#7593A9"
    }
  },
});
export default GeniusTheme;