import CssBaseline from "@mui/material/CssBaseline";
import { Theme, ThemeProvider } from "@mui/material/styles";
import { Suspense, useEffect } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import {
  AuthContext,
  useAuthentication,
  useProvideAuth,
} from "../../hooks/Authentication";
import { ClientContext, useProvideClient } from "../../hooks/Client";
import { IClient } from "../../hooks/Client/ClientContext";
import {
  CuberTheme,
  GeniusTheme,
  GirasolTheme,
  HeliosTheme,
  RhodasTheme,
} from "../../themes/";
import {
  LazyDashboardPage,
  LazyInstallationDetailPage,
  LazyInstallationPage,
  LazyLayoutPage,
  LazyLoginPage,
  LazyPreferencePage,
  LazyTablePage,
  LazyUserPage,
} from "../";
import { Features } from "../../models/Features";

const PrivateRoute = ({ layout, children, ...rest }: any) => {
  const auth = useAuthentication();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (auth.isAuthenticated) {
          return <LazyLayoutPage>{children}</LazyLayoutPage>;
        } else {
          return (
            <Redirect
              exact
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
};

const App = () => {
  const authProvider = useProvideAuth();
  const clientProvider = useProvideClient();

  useEffect(() => {
    window.addEventListener("unhandledrejection", function (event) {
      event.preventDefault();
    });
  }, []);

  const getTheme: (client: IClient) => Theme = (client: IClient) => {
    switch (client.name) {
      case "Girasol":
        return GirasolTheme;
      case "Helios":
        return HeliosTheme;
      case "Genius":
        return GeniusTheme;
      case "RHODaS":
        return RhodasTheme;
      default:
        return CuberTheme;
    }
  };

  return (
    <ClientContext.Provider value={clientProvider}>
      <AuthContext.Provider value={authProvider}>
        <ThemeProvider theme={getTheme(clientProvider.client)}>
          <CssBaseline />
          <Suspense fallback={<>Loading...</>}>
            <Router>
              <Switch>
                <PrivateRoute exact path="/">
                  <LazyDashboardPage />
                </PrivateRoute>
                <Route path="/login">
                  <LazyLoginPage />
                </Route>
                <PrivateRoute path="/dashboard">
                  <LazyDashboardPage />
                </PrivateRoute>
                <PrivateRoute path="/users">
                  <LazyUserPage />
                </PrivateRoute>
                <PrivateRoute exact path={`/${clientProvider.client.pagePath}`}>
                  <LazyInstallationPage />
                </PrivateRoute>
                <PrivateRoute path={`/${clientProvider.client.pagePath}/:id`}>
                  <LazyInstallationDetailPage />
                </PrivateRoute>
                {clientProvider.client.features.some(
                  (c) => c === Features.Preferences
                ) && (
                  <>
                    <PrivateRoute path="/tables/:id">
                      <LazyTablePage />
                    </PrivateRoute>
                    <PrivateRoute path="/preferences">
                      <LazyPreferencePage />
                    </PrivateRoute>
                  </>
                )}
              </Switch>
            </Router>
          </Suspense>
        </ThemeProvider>
      </AuthContext.Provider>
    </ClientContext.Provider>
  );
};

export default App;
