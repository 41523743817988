import { createTheme } from "@mui/material/styles";

const RhodasTheme = createTheme(
  {
    palette: {
      common: {
        black: "#272727"
      },
      primary: {
        main: '#373f51',
        light: "#5f6573",
        dark: "#262c38"
      },
      secondary: {
        main: '#ffc200',
        light: "#ffce33",
        dark: "#b28700"
      },
    }
  });
export default RhodasTheme;