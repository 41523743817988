import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import ReactDOM from "react-dom";
import { reactPlugin } from "./appinsights";
import "./index.css";
import App from "./pages/App/App";
import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.min.css";
import ErrorPage from "./pages/ErrorPage/ErrorPage";

ReactDOM.render(
  <AppInsightsContext.Provider value={reactPlugin}>
    <AppInsightsErrorBoundary
      onError={() => <ErrorPage />}
      appInsights={reactPlugin}
    >
      <App />
    </AppInsightsErrorBoundary>
  </AppInsightsContext.Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
