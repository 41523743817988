import { createTheme } from '@mui/material/styles';

const HeliosTheme = createTheme({
  palette: {
    common: {
        black: "#272727"
    },
    primary: {
        main: "#117a65",
        light: "#4daa93",
        dark: "#004d3b"
    },
    secondary: {
      main: "#44b29c",
      light: "#79e5cd",
      dark: "#00826e"
    }
  },
});
export default HeliosTheme;