export interface INotification {
  warnings: boolean;
  alarms: boolean;
  recommendations: boolean;
  actions: boolean;
}

export enum RoleType {
  Administrator,
  Operator,
  Guest,
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: RoleType;
  notification: INotification;
  culture: string;
  password: string;
  createdAt: Date;
  lastLoginDate: Date;
  assignments: IAssignment[];
}

export interface IAssignment {
  installationId: string;
  assignmentType: AssigmentType;
}

export enum AssigmentType {
  Owner,
  Operator,
  Monitor,
}
