import { createTheme }  from "@mui/material/styles";

const CuberTheme = createTheme({
  palette: {
    common: {
        black: "#272727"
    },
    primary: {
        main: "#822619",
        light: "#b75441",
        dark: "#500000"
    },
    secondary: {
      main: "#638219",
      light: "#93b249",
      dark: "#355500"
    }
  },
});
export default CuberTheme;