import { lazy } from "react";

const LazyDashboardPage = lazy(() => import("./DashboardPage/DashboardPage"));
const LazyInstallationDetailPage = lazy(
  () => import("./InstallationDetailPage/InstallationDetailPage")
);
const LazyInstallationPage = lazy(
  () => import("./InstallationPage/InstallationPage")
);
const LazyLayoutPage = lazy(() => import("./LayoutPage/LayoutPage"));
const LazyLoginPage = lazy(() => import("./LoginPage/LoginPage"));
const LazyPreferencePage = lazy(
  () => import("./PreferencePage/PreferencePage")
);
const LazyTablePage = lazy(() => import("./TablePage/TablePage"));
const LazyUserPage = lazy(() => import("./UserPage/UserPage"));

export {
  LazyDashboardPage,
  LazyInstallationDetailPage,
  LazyInstallationPage,
  LazyLayoutPage,
  LazyLoginPage,
  LazyPreferencePage,
  LazyTablePage,
  LazyUserPage,
};
